import React, { Suspense } from 'react';

import { Layout } from '@components/layout';
import { LeaveComplete } from '@features/mypage/LeaveComplete';

const HistoryPage = () => (
  <Layout>
    <Suspense fallback={<p>Loading...</p>}>
      <LeaveComplete />
    </Suspense>
  </Layout>
);

export default HistoryPage;
