import React, { FC } from 'react';

import { ReactComponent as TastyLogo } from '@assets/image/svg/tasty-logo.svg';
import { ReactComponent as ThankYouLogo } from '@assets/image/svg/thank-you.svg';
import { Button } from '@components/controls/button';
import { Block } from '@components/layout/block';
import { Center } from '@components/layout/center';
import { VStack } from '@components/layout/vstack';
import { Heading2 } from '@components/typographies/heading';
import { Paragraph } from '@components/typographies/paragraph';

export const LeaveComplete: FC = () => (
  <Center>
    <VStack spacing="10px">
      <Heading2 fontSize="24px" lh="40px">
        退会手続きが完了いたしました
      </Heading2>
      <TastyLogo />
      <ThankYouLogo />
      <Block margin="30px 0">
        <Paragraph>Tastyをご利用いただきありがとうございました。</Paragraph>
        <Paragraph>またのご利用をお待ちしております。</Paragraph>
      </Block>
      <Button radius="5px" width="196px" bg="gray2" border="1px solid #d9d9d9d9">
        TOPページへ戻る
      </Button>
    </VStack>
  </Center>
);
